import React, {useState, useEffect} from 'react';
import CalendarDay from "../calandarDay/calendarDay";
import {Dish} from "../../../models/dish";
import {CalendarEntry} from "../../../models/calendarEntry";
import styled from 'styled-components';


const Container = styled.div`
   display: flex;
   /*flex-flow: nowrap;*/
   flex-flow: wrap;
   justify-content: space-evenly;
   /*border: 0.2rem solid red;*/
`;

const WeekHeader = styled.h2`
   background-color: orange;
`;

const CalendarWeek = () => {   
   const [availableDishes, setAvailableDishes] = useState<Dish[]>([]);
   useEffect(() => {
      fetch("https://tabfam-api-staging.herokuapp.com/api/dish/")
        .then(res => res.json())
        .then(
          (result) => {
            let dishes: Dish[] = [];
            result !==undefined && result.forEach((e:any, i:number) => {
               dishes.push(new Dish(e.title));
            })
            setAvailableDishes(dishes);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error)
          }
        )
   }, [])
  
   const getRandomDish = (calendarEntries: CalendarEntry[] = []) => {
      let dishesToPick = availableDishes.filter(d => calendarEntries.filter(e => e.dish && e.dish.title === d.title).length === 0 )
      return dishesToPick[Math.floor(Math.random() * dishesToPick.length)];
   }
   let tempEntries: CalendarEntry[] = [];
   ["Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag", "Søndag"].forEach((e, i) => {
         tempEntries.push(new CalendarEntry(e, getRandomDish(tempEntries)))
   });
   const [calendarEntries, updatecalendarEntries] = useState([...tempEntries]);
   let keepChanger = (day:number) => {
      return (e:any) => {
         calendarEntries[day].keep = !calendarEntries[day].keep;
         updatecalendarEntries([...calendarEntries]);
      };
   };
   const randomAll = () => {
      calendarEntries.forEach((e,i) => {if(!e.keep){e.dish = getRandomDish(calendarEntries)}});
      updatecalendarEntries([...calendarEntries]);
   };

   return <div>
      <WeekHeader>Ukeplan</WeekHeader>
      <Container>
         {calendarEntries.map((e, i) => {
            return <CalendarDay key={e.weekday} entry={e} changeKeep={keepChanger(i)}/>
            })
         }
      </Container>
      <div><button onClick={randomAll}>Foreslå nye retter</button></div>
   </div>
};

export default CalendarWeek;