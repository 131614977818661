import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin: 2rem;
    border: 0.2rem solid #282c34;
    padding: 0.3rem;
    border-radius: 0.5rem;
    box-shadow: #282c34 0.2em 0.2rem;
`;

const DishThumbnail = styled.img`
    max-width: 8vw;
`;

const WeekDayHeader = styled.h2`
    background-color: transparent;
    border-bottom: 0.2rem solid red;
`;

const CalendarDay = (props: any) => {
    // FIXME: remove redundant ui code
    if (!props.entry.dish)
        return <Container><WeekDayHeader>{props.entry.weekday}</WeekDayHeader><h3>Ingen rett valgt</h3><input type="checkbox" value={props.entry.keep} onChange={props.changeKeep}/>Behold uten rett</Container>
    return <Container>
        <WeekDayHeader>{props.entry.weekday}</WeekDayHeader>
        <h3>{props.entry.dish.title}</h3>
        <DishThumbnail alt={"Thumbnail for " + props.entry.dish.title}
             src={props.entry.dish.imageUrl} />
         <br />
        <input type="checkbox" value={props.entry.keep} onChange={props.changeKeep}/>Behold
    </Container>
};

export default CalendarDay;