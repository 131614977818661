import { createGlobalStyle } from "styled-components";

export const BaseStyles = createGlobalStyle`
  body {
    /* font-family: Montserrat, Verdana, sans-serif; */
    font-size: 16px;
    /* line-height: 1rem; */
    color: rgb(0,0,0);
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
  }

  /* https://zellwk.com/blog/why-vertical-rhythms/ */
  /* h2 {
      font-size: 2rem;
      line-height: 2rem;
  }

  h3 {
      font-size: 1.5rem;
      line-height: 1.5rem;
  } */

`;