import React from 'react';
import { useAuth0 } from './react-auth0-spa';

const Profile = () => {
    const {isAuthenticated, user} = useAuth0();
    console.log(user);
    console.log(isAuthenticated);
    
    return <div>
        <h1>Du er logget inn som {user.name}</h1>
        <img src={user.picture} alt="profile"/>
    </div>
}
export default Profile;