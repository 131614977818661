import {Dish} from "./dish";

export class CalendarEntry {
    dish: Dish;
    keep: boolean;
    weekday: string;

    constructor(weekday:string, dish: Dish) {
        this.dish = dish;
        this.weekday = weekday;
        this.keep = false
    }
}