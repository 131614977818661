import React from 'react';
import { useAuth0 } from './react-auth0-spa';
import { useLocation } from 'react-router-dom';

const Login = () => {
    const {loginWithRedirect} = useAuth0();
    const location: any = useLocation();

    //FIXME: errorprone, requirering from and pathname in state
    const targetUrl = location.state ? location.state.from.pathname : "/"
    
    return <div><p>Det ser ikke ut som du er logget inn. Klikk på knappen under for å gjøre det :) </p><button onClick={() => loginWithRedirect({appState: {targetUrl: targetUrl}})}>Log in</button></div>
};

export default Login;