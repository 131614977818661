

export class Dish {
    title: string;
    imageUrl: string
    constructor(title: string) {
        this.title = title;
        this.imageUrl = "https://image.shutterstock.com/image-vector/ui-image-placeholder-wireframes-apps-260nw-1037719204.jpg"
    }
}

